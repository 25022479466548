import { CellClassParams, ColumnApi, GridApi, GridOptions, RowClickedEvent } from 'ag-grid-community';
import moment from 'moment';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, debounceTime, switchMap } from 'rxjs/operators';

import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviourService } from '@pa/lib-spa';
import { OriginatorType } from '@pa/references/idf';
import { Behaviour } from '@pa/sdk/idf';

import { PolicyService } from '../services/policy.service';
import { QuoteService } from '../services/quote.service';
import { formatDate } from '../utils/date';

interface colDefs {
    id: string;
    policyNumber: string;
    insuredName: string;
    broker: string;
    revision: string;
    transactionType: string;
    effectiveDate: string;
    createdDate: string;
}
@Component({
    selector: 'app-quote-policy-home-page',
    templateUrl: './quote-policy-home-page.component.html',
    styleUrls: ['./quote-policy-home-page.component.scss'],
})
export class QuotePolicyHomePageComponent implements OnInit {
    public behaviours: Behaviour[] = [];
    behaviourSelected: Behaviour | undefined;
    dataLoading: boolean = true;
    behavioursLoading = true;
    gridApi: GridApi;
    gridColumnApi: ColumnApi;
    paginationPageSize: number = 50;
    rowData: colDefs[] = [];
    searchDisabled: boolean = true;
    searchQuery: string = '';
    showBehaviourSelector: boolean = true;
    showTable: boolean = false;
    search$: Subject<string> = new Subject<string>();

    gridOptions: GridOptions = {
        columnDefs: [
            {
                field: 'id',
                hide: true,
            },
            {
                headerName: 'Policy Number',
                field: 'policyNumber',
                cellClass: this.formTestId,
                flex: 1,
            },
            {
                headerName: 'Insured Name',
                field: 'insuredName',
                cellClass: this.formTestId,
                flex: 1,
            },
            {
                headerName: 'Broker',
                field: 'broker',
                cellClass: this.formTestId,
                flex: 1,
            },
            {
                headerName: 'Revision',
                field: 'revision',
                cellClass: this.formTestId,
                flex: 1,
            },
            {
                headerName: 'Transaction Type',
                field: 'transactionType',
                cellClass: this.formTestId,
                flex: 1,
            },
            {
                headerName: 'Effective Date',
                field: 'effectiveDate',
                cellClass: this.formTestId,
                flex: 1,
            },
        ],
        getRowClass: this.formRowTestId,
        rowHeight: 48,
        suppressRowHoverHighlight: false,
        overlayNoRowsTemplate: '<span>Data not available</span>',
        singleClickEdit: true,
        rowStyle: { cursor: 'pointer' },
        animateRows: true,
        onRowClicked: (event: RowClickedEvent) => {
            let url: string;
            if (event.data.policyNumber) {
                url = `/clientPolicy?policyId=${event.data.id}&behaviourId=${this.behaviourSelected._id}`;
            } else {
                url = `/viewQuote?proposalId=${event.data.id}&behaviourId=${this.behaviourSelected._id}`;
            }
            this.router.navigateByUrl(url);
        },
    };

    onGridReady(params): void {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi?.setRowData(this.sortRowDataByCreatedDate());
    }

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private policyService: PolicyService,
        private quoteService: QuoteService,
        private router: Router,
        private behaviourService: BehaviourService
    ) {
        // debounce search by 750ms
        this.search$
            .pipe(
                debounceTime(750),
                switchMap((searchText) => {
                    this.dataLoading = true;
                    this.searchQuery = searchText;
                    if (searchText) {
                        this.rowData = [];
                    }

                    // Update the router params
                    this.updateRouterState();

                    const today = moment.utc().toISOString();
                    const fromDate = moment(today).clone().subtract(30, 'days').toISOString();
                    const query = {
                        behaviourId: this.behaviourSelected._id,
                        behaviours: this._getBehaviourGroup(),
                    };

                    if (searchText) {
                        query['searchTerm'] = searchText;
                    } else {
                        query['createdDateFrom'] = fromDate;
                        query['createdDateTo'] = today;
                    }

                    // Fetch quotes and policies
                    return forkJoin([
                        this.quoteService.getESQuotes(query).pipe(catchError((err) => of([]))),
                        this.policyService.getESPolicies(query).pipe(catchError((err) => of([]))),
                    ]);
                })
            )
            .subscribe({
                next: ([quotes, policies]) => {
                    const newQuotes = this.filterClientProposal(quotes);
                    this.rowData.push(...this._mapESData([...(newQuotes ?? []), ...(policies ?? [])]));
                    this.gridApi?.setRowData(this.sortRowDataByCreatedDate());
                    this.dataLoading = false;
                },
                error: (err) => {
                    this.dataLoading = false;
                },
            });
    }

    ngOnInit(): void {
        this.showTable = false;

        // Fetch behaviours from cognito, if not set already
        if (!this.behaviours.length) {
            this.quoteService.getBehavioursfromCognito().subscribe((behaviours) => {
                this.behaviours = behaviours;
                this.behavioursLoading = false;

                // Fetch the behaviour id (behaviour) and search query (q) from the query params
                this.route.queryParams.subscribe((params: { behaviour: string; q: string }) => {
                    if ('behaviour' in params) {
                        const queriedBehaviour = behaviours.find((behaviour) => behaviour._id === params.behaviour);
                        this.searchQuery = params.q;

                        // Update the behaviour, in force update
                        this.updateBehaviour(queriedBehaviour, true);
                        // Update the search results
                        this.search();

                        this.showBehaviourSelector = false;
                        this.showTable = true;
                        this.searchDisabled = false;
                    } else {
                        this.showBehaviourSelector = true;
                        this.showTable = false;
                        this.searchDisabled = true;
                    }
                });
            });
        }
    }

    protected formRowTestId(params: CellClassParams): string {
        const { insuredName: insuredNameRaw, revision: revisionString, policyNumber } = params.data;
        const insuredName = insuredNameRaw.replaceAll(' ', '_');
        const revision = revisionString.replace('Revision ', '') || 0;
        const type = policyNumber ? 'policy' : 'quote';
        const tokens = [insuredName, type, revision];
        if (type === 'policy') tokens.push(policyNumber);
        return 'data-testid-' + tokens.join('__');
    }
    protected formTestId(params: CellClassParams): string {
        // Form the data-testid attribute value
        let dataTestId = params.data.insuredName.replaceAll(' ', '_');
        // Append policy number and revision if available
        if (params.data.policyNumber) dataTestId += `-${params.data.policyNumber}`;
        if (params.data.revision) dataTestId += `-${params.data.revision.replaceAll(' ', '_')}`;
        // Return the data-testid attribute value
        return `data-testid-${dataTestId}-${params.colDef.field}`;
    }

    /** @description Search query value being passed into search$ observable */
    search(): void {
        this.search$.next(this.searchQuery);
    }

    /** @description Update the behaviour by selecting the behaviour or through code with force  */
    updateBehaviour(behaviour: Behaviour, forcedUpdate?: boolean) {
        this.behaviourService.set(behaviour);
        this.behaviourSelected = behaviour;

        if (!forcedUpdate) {
            this.searchQuery = '';
        }
    }

    /** @description update table data by behaviour selected latest  */
    searchByBehaviour() {
        this.rowData = [];
        this.showTable = true;
        this.showBehaviourSelector = false;
        this.dataLoading = true;

        // Update the router params
        this.updateRouterState();

        const today = moment.utc().toISOString();
        const fromDate = moment(today).clone().subtract(30, 'days').toISOString();
        const query = {
            createdDateFrom: fromDate,
            createdDateTo: today,
            behaviourId: this.behaviourSelected._id,
            behaviours: this._getBehaviourGroup(),
        };
        forkJoin([
            this.quoteService.getESQuotes({ quoteStatus: 'none', ...query }).pipe(catchError((err) => of([]))),
            this.policyService.getESPolicies(query).pipe(catchError((err) => of([]))),
        ]).subscribe({
            next: ([quotes, policies]) => {
                const newQuotes = this.filterClientProposal(quotes);
                this.rowData.push(...this._mapESData([...(newQuotes ?? []), ...(policies ?? [])]));
                this.gridApi?.setRowData(this.sortRowDataByCreatedDate());
                this.searchDisabled = false;
                this.dataLoading = false;
            },
            error: (err) => {
                this.dataLoading = false;
            },
        });
    }

    /** @description Map the data from ES to the grid */
    private _mapESData(data: any[]): any[] {
        return data.map((d) => {
            let effectiveDate: string = '';
            if (d.reference) {
                effectiveDate = this._getEffectiveDate([
                    d.inceptionDate,
                    d.amendedDate,
                    d.renewalDate,
                    d.cancellationDate,
                ]);
            } else {
                effectiveDate = this._getEffectiveDate([
                    d.inceptionDate,
                    d.amendmentEffectiveDate,
                    d.renewalEffectiveDate,
                    d.cancellationEffectiveDate,
                ]);
            }

            let row: colDefs = {
                id: d._id,
                policyNumber: d.reference ? d.reference : '',
                broker: [OriginatorType.api, OriginatorType.intermediary, OriginatorType.insuredPolicyManager].includes(
                    d.originator.type
                )
                    ? d.originator.individual
                    : 'Direct',
                revision: this.getRevisionInfo(d),
                transactionType: d.reference ? '' : d.transactionType,
                effectiveDate: formatDate(
                    moment.utc(effectiveDate).toISOString(),
                    d.clientProposal?.timezone ?? d.timezone
                ),
                createdDate: d.createdDate,
                insuredName: d.company.companyName,
            };
            return row;
        });
    }

    /** @description  Sort the row data by effective date */
    private sortRowDataByCreatedDate() {
        return this.rowData.sort((a, b) => {
            const element1 = moment(a.createdDate);
            const element2 = moment(b.createdDate);

            if (element1.isSame(element2)) return 0;
            if (element1.isBefore(element2)) return 1;
            return -1;
        });
    }

    convertTZ(date, tzString) {
        return new Date(
            (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: tzString })
        );
    }

    private getRevisionInfo(data) {
        if (data.reference) {
            if (data.referenceRevisionRevision) {
                return `Revision ${data.referenceRevision || 0} - ${data.referenceRevisionRevision}`;
            } else if (data.referenceRevision) {
                return `Revision ${data.referenceRevision}`;
            } else {
                return ``;
            }
        } else if (data.clientProposal) {
            return data.clientProposal.revision ? `Revision ${data.clientProposal.revision}` : '';
        } else {
            return '';
        }
    }

    private _getEffectiveDate(dates: string[]) {
        return dates.reduce((d1, d2) => {
            if (!d1 && !d2) {
                return;
            } else if (!d1) {
                return d2;
            } else if (!d2) {
                return d1;
            }
            return d1 > d2 ? d1 : d2;
        });
    }

    private _getBehaviourGroup() {
        return [
            this.behaviourSelected.insurer,
            this.behaviourSelected.product,
            this.behaviourSelected.locale,
            this.behaviourSelected.market,
        ].join('_');
    }

    /** @description Update the router state into current url */
    private updateRouterState() {
        const url = this.router.createUrlTree(['/quotes-policies'], {
            relativeTo: this.route,
            queryParams: { behaviour: this.behaviourSelected._id, q: this.searchQuery },
            queryParamsHandling: 'merge',
        });

        this.location.go(url.toString());
    }

    filterClientProposal(quotes: any[]): any[] {
        const newQuotes: any[] = [];
        quotes.forEach((eachQuote) => {
            // Checks for similar clientProposals
            const checkRefIndex = newQuotes.findIndex((val) => val._id === eachQuote._id);
            if (checkRefIndex < 0) {
                // if no similar proposals found in newQuotes array
                newQuotes.push(eachQuote);
            } else if (eachQuote.revision) {
                // if similar proposals found in newQuotes array then
                if (
                    (newQuotes[checkRefIndex].revision && newQuotes[checkRefIndex].revision < eachQuote.revision) ||
                    !newQuotes[checkRefIndex].revision
                ) {
                    newQuotes.splice(checkRefIndex, 1, eachQuote);
                }
            }
        });
        return newQuotes;
    }
}
